
.reset-button{
     background-color:#4c0741!important ;
}
.profileBackground{
    margin-top: -7%!important;
    margin-left: -3%;
    width: 110% !important;
    z-index: 20;

}

.profile-img{
  margin-top: 170px;
  width: 100px!important;
  height: 100px!important;
  object-fit: cover!important;
  position: relative!important;
  margin-top: 230px!important;
  width: 100%;

  border: 3px solid white;

}
.slider{
 width: 100%!important;
}
/* @media screen and (min-width: 900px) {
    .profileBackground{
     width: auto;
     margin-top: -17px;
    
   
    

} */
    
    
  
/* } */
.profile-user-row{
    margin-left: 150px!important;
}
.user-name{
    color: white;
    font-weight: 600;
}
.profile-designation{
    font-size: 13px;
    font-weight: 600;
    color: #bdb6b6;
}
.profile-button{
    color: white!important;
    background-image: linear-gradient(to bottom left, blueviolet, blueviolet, blueviolet)!important;
    border-radius: 10px!important;
}
.add-button{
     background-image: linear-gradient(to bottom left, black, black, black)!important;
    border-radius: 10px!important;

}
.background-upload{
    font-size: 40px!important;
    cursor: pointer;
    color:grey!important;
}
.profile-posts{
    border-radius: 30px 30px 30px 30px!important;
    height:250px!important;
    width: 100%;
    
}
.profile-div{
    overflow-x: hidden!important;
}


.content::-webkit-scrollbar-track {
  background-color: #F5F5F5;
}

.content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
} 


.content::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  
}
input {
  color: white!important;
  
}

