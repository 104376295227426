.canva-anim{
 
 width: auto!important;
 height: auto;
 background: linear-gradient(to bottom left, #1e1e2b, #1e1e2b)!important;
 background: linear-gradient(to bottom left, #1e1e2b, #1e1e2b)!important;

}


.landing-heading{
   
  color: white;
  font-size: 50px!important;
  font-weight: 700;
  font-style: bold;
 
  
}
.landing-subheading{
   
  color: white!important;
  font-size: 28px;
  font-weight: 400;
  
  



}
.landing-div {
  margin-top: 7%;
  
}
.landing-button{
    background-image: linear-gradient(to bottom left, #161616, #161616, #161616)!important;
    font-size: 20px!important;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.9)!important;
    
}
.gradient-text{
    background: linear-gradient(to bottom left, #6A097D, #a248b4, #f4f1f5)!important;
  -webkit-background-clip: text!important; 
  background-clip: text!important;
  color: transparent!important;

}
.accordion-button {
    border: none !important;
    background: transparent;
    font-size: 15px;
     -webkit-background-clip: text!important; 
  background-clip: text!important;
  color: black!important;
  font-size: 25px;
  font-weight: 600;
}

.accordion-button:focus {
    outline: none !important;
    
}

.accordion-item{
  margin-top: 5%;
  background-color: #fff;
    border-radius: 12px;
    padding: 18px;
    transition: background-color .2s;
   
 
}

.accordion-body{
    
    font-family: Rubik, sans-serif;
    font-size: 22px;
    line-height: 150%;

   
 
}
.tips-block {
    z-index: 0;
    justify-content: center;
    align-items: center; 
     margin-top: -1400px;
    padding-top: 1500px;
    display: flex;
    position: relative;
    overflow: hidden; 
   
}
.tips-block-img{
    max-width: 100%!important;
    vertical-align: middle!important;
    display: inline-block!important;
    
}
.tips-block-inner {
    will-change: opacity, transform!important;
    opacity: 1!important;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)!important;
    transform-style: preserve-3d!important;
    width: 359px!important;
    position: absolute!important;
}
.tip-block-inner-img-one{
      max-width: 100%!important;
    vertical-align: middle!important;
    display: inline-block!important;
        z-index: 2!important;
    opacity: 0!important;
    position: relative!important;
        will-change: opacity!important;
    opacity: 0!important;
}
.tip-block-inner-img-two{
          z-index: 1!important;
    position: absolute!important;
    top: 0%!important;
    bottom: auto!important;
    left: 0%!important;
    right: auto!important;
    will-change: opacity!important;
    opacity: 1!important;
       max-width: 100%!important;
    vertical-align: middle!important;
    display: inline-block!important;
    height:100%;
}
.tip-block-chats {
    z-index: 2;
    width: 70%;
    height: 470px;
    position: absolute;
}
.tip-block-chat-one {
        transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    opacity: 1;
    top: 0;
    left: 0;
}
.tip-block-chat-two {
     transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    opacity: 1; 
    top: 250px!important;
    right:5%;
    position: inherit;
    
   
}
.tip-block-chat-three {
          transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    opacity: 1;
    top: 350px;
    position: inherit;
}

 @media screen and (max-width: 650px){
 .tip-block-chats{
  display: none;
}
} 



