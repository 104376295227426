.dropDown{
    
    background-image: linear-gradient(to bottom left, black, black, black)!important;
    border-radius: 100px 100px 100px 100px!important;
     padding: 0.5rem 1rem!important;
  font-size: 0.75rem!important;
    background-color:  black!important;
  
}
.bottom-left {
  position: absolute!important;
  bottom: 0!important;
  
  z-index: 1!important;
}
.contact-profile{
   
    position:relative!important;
     object-fit: cover!important;
    z-index: 4!important;
    margin-top: -40px!important;
   
  
    width:30px;
    height: 30px;
    color: aliceblue;

}
.contact-profile-one{
    border-radius: 50%;
    position:relative!important;
     object-fit: cover!important;
    z-index: 4!important;
    margin-top: -30px!important;
    margin-left: -20px;
    border:1px solid white;
   
   
    width:40px;
    height: 40px;

}
.country-menu{
   
   overflow: auto !important;
   height:50vh!important;
   background-color:   #1e1e2b!important
}
.simple-menu{
  
   
   background-color: #1e1e2b!important;
   overflow: auto !important;
   
}
.simple-menu:before{
   
   
   display: none !important;
  
}

.country-menu::-webkit-scrollbar {
  width: 8px;


  
}

.country-menu::-webkit-scrollbar-track {
  background-color:   #1e1e2b!important
}

.country-menu::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}


.country-menu::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  
}
/* .ads-row > .ads-card {
  zoom: 0.70!important;
  margin-left: 3%!important;
}

.ads-row > .ads-card:last-child {
  margin-right: 0!important;
} */
.toggle-down{
  padding-right: 3rem!important;
  padding-left: 3rem!important;
  font-size: 16px!important;
}
.toggle-down-city{
  padding-right: 2rem!important;
  padding-left: 2rem!important;
  font-size: 16px!important;
}