.home-title {
  color: white !important;
  font-weight: 600;
}

.home-search {
  border-color: white !important;
}



.home-input-addon {
  margin-left: 100px;
}

.home-badge {
  margin-left: -0.2em !important
}

.image-list {
  list-style: none !important;
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
}

.image-list li {
  margin-right: -20px !important;

}

.image-list li img {
  border-radius: 50% !important;
  width: 60px !important;
  height: 60px !important;
  object-fit: cover !important;
  transform: translateX(-20px) !important;
  transition: transform 0.5s !important;
  border: 2px solid red;
}

.image-list li:hover img {
  transform: translateX(0);
}

.card-main {

  border-radius: 20px !important;
  

 

  margin-top: 30px;
  border: none;
 
  background-image: linear-gradient(to bottom left, #1e1e2b, #1e1e2b) !important;
  box-shadow: none !important;
 



}
.hide-controls lottie-player::part(controls-container) {
  display: none!important;
}

.second-card-main {

  border-radius: 20px !important;

  

  margin-top: 20px !important;
  border: none;
  
  background-image: linear-gradient(to bottom left, #1e1e2b, #1e1e2b) !important;
  box-shadow: none !important;
 


}

.card-img-top {
  width: 50px !important;
  height: 50px !important;
  object-fit: cover !important;
  position: absolute !important;
  top: -20px !important;
  left: 30px !important;
  overflow: hidden !important;
  background-color: #1e1e26;
  padding: 3px;
  border: none !important;
  cursor: pointer !important;
  z-index: 100;





  border: 3px solid green;

}

.card-footers {
  
  position: absolute;
  bottom: -10px;
  padding: 10px;
  border:none;
  margin-right: 10%;
  
 
  
  
  
}

.card-link {
  color: white !important;
  margin-left: 10px !important;
  font-size: 20px !important;
}

.home-chats {
  background-color: #161616;
  height: auto;
  border-radius: 50px 0 0 10px;

}

.chats-col {
  margin-top: -80px;
  overflow-x: hidden !important;

}



.chat-img-top {
  width: 70px !important;
  height: 70px !important;
  object-fit: cover !important;
  margin-top: 50px;
}

.chat-text {
  color: white;
  font-size: 15px;
  font-weight: 600;
}

.chat-designation {
  color: #BFB8B8;
  font-size: 10px;
  font-weight: 600;
}

figure {
  display: inline-block !important;
  margin: 0 !important;
}

figcaption {
  font-weight: bold !important;
  font-size: 1.2rem !important;
  margin-bottom: 0 !important;
}

p {
  margin-top: 0 !important;
}

.chat-img {
  width: 70px !important;
  height: 70px !important;
  margin-left: 30px;
  border: 3px solid skyblue;
}

.chat-text-col {
  margin-right: 110px;
}
.lock-img{
  width: 150px; 
  height: 150px;
  cursor: pointer;
  
}
/* @media screen and (max-width: 650px){
 .lock-img{
  position: absolute!important;
  margin-top: -60%!important;
  width: 200px !important;
  height: 200px !important;
  margin-left: -10%!important;
  cursor: pointer!important;
}
} */
.live-img{
  list-style: none !important;
  padding: 0 !important;
  margin: 0 !important;
  display: flex !important;
  
  
}
.live-img li {
   margin-right: 5px !important;
  
}

.live-img li img {
  
  
  border-radius: 50% !important;
  width: 60px !important;
  height: 60px !important;
  object-fit: cover !important;
  transform: translateX(0) !important;
  transition: transform 0.5s !important;
  border: 2px solid red;
}

/* Hide the default scrollbar */
body::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

/* Track */
body::-webkit-scrollbar-track {
  background-color: #F5F5F5;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.add-icon{
  color:white;
  font-Size:35px;
  background:#1e1e26;
  border-radius:20px 0 0 0;
  padding-top:11px;
  cursor:pointer;
 

}
.card-content-container {
  position: relative!important;
 /* Adjust as needed */
}

video::-webkit-media-controls-start-playback-button {
  display: none!important;
}