
.chat-img-top{
  width: 70px!important;
  height: 70px!important;
  object-fit: cover!important;
  margin-top: 50px;
 
}
.upper-profile{
  width: 70px!important;
  height: 70px!important;
  object-fit: cover!important;
 
 
}
.chat-text{
    color:white;
    font-size: 15px;
    font-weight: 600;
   
}
.chat-text-inner{
    color:white;
    font-size: 20px;
    font-weight: 600;
}
.chat-msg-inner{
    color:white;
    font-size: 18px;
    font-weight: 600;
}
.chat-designation{
    color:#BFB8B8;
    font-size: 10px;
    font-weight: 600;
}
figure {
  display: inline-block!important;
  margin: 0!important;
}

figcaption {
  font-weight: bold!important;
  font-size: 1.2rem!important;
  margin-bottom: 0!important;
}

p {
  margin-top: 0!important;
}
.chat-img{
    width:70px !important;
    height:70px !important;
    margin-left: 30px;
    border: 3px solid skyblue;
    cursor: pointer;
}

.chat-text-col{
    margin-right:110px ;
    margin-left: 7px;
    cursor: pointer;
}
.home-chats{
    background-color: #161616;
    width: 377px!important;
    
    border-radius: 50px 0  0 10px;
    position: fixed;
    margin-top: -10px!important;
  
  right: 0;
  
  
  height: 150vh;
 
  overflow: auto;
  top: 0;
  bottom: 0;
  
  
    
  
}

.chat-div{
    
    position: fixed;
     top: 0;
  bottom: 0;
  

  
 
  
  
    
  
}
.chat-div:hover {
  overflow: auto;
  top: 0;
  bottom: 0;
  
  
  
}


.chat-div::-webkit-scrollbar {
  width: 8px;
  display: none!important;
  
}

.chat-div::-webkit-scrollbar-track {
  background-color: #F5F5F5;
}

.chat-div::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}


.chat-div::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  
}

.chat-div:hover::-webkit-scrollbar-thumb {
  background-color: #555;
}
.chat-toggle {
  position: fixed;
  top: 50%;
  right: -40px;
  transform: translateY(-50%);
  background-color: #161616;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  transition: right 0.3s ease;
}
/* @media (max-width: 900px) {
  .home-chats {
    margin-right: -100%;
  }

  
} */
.noti-div{
  background-color: #161616!important;
  border-radius: 30px 0px 30px !important;
  opacity: 0.9!important;
  position: absolute;
  z-index: 300!important;
  margin-left: 100%!important;
  margin-top: 100%!important;


}
.home-noti-bell{
 background-image: linear-gradient(to bottom left, #161616, #161616, #161616)!important;
     
}
.home-noti-div {
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 30px 30px 30px 30px !important;
  position: fixed !important;
  margin-right: 100% !important;
  width: 400px !important;
  margin-left: 57% !important;
  z-index: 5000;
  height: 100vh !important;
  zoom: 0.75;
  overflow: hidden;
  margin-top: -1%!important;
}

.home-main-noti-div {
  position: relative;
  margin-left: 40px !important;
  z-index: 6000 !important;
  height: 100%;
  overflow: auto;
}
.home-main-noti-div:hover{
  overflow: auto!important;
  
 
 
  
  
  
}
.home-main-noti-div::-webkit-scrollbar {
  
  display: none!important;
  
}

.home-main-noti-div::-webkit-scrollbar-track {
  background-color: #F5F5F5;
}

.home-main-noti-div::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
} 


.home-main-noti-div::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  
}
.vertical-progress-bar-menu{
   height: 100vh!important;
  width: -100px!important;
  position: absolute!important;
  background-color: #666363!important;
  margin-left: 10%!important;
  margin-top: -4%!important;
  box-shadow: 0px 0px 0px 3px #666363!important;
  border-radius: 50%!important;

}
.horizontal-progress-bar-menu{
  height: 0!important;
  /* width: 0!important; */
width: 50vh !important;
margin-top:15%!important;
  /* background-color: white!important; */
  margin-left: 5%!important;
  box-shadow: 0px 0px 0px 3px #666363!important;
}


.chat-noti-profile{
margin-top: 40px!important;
margin-left: 8%!important;
height: 30px;
width:30px;

}
.chat-noti-two-profile{
margin-top: 80px!important;
margin-left: 12%!important;

}
.chat-noti-profile-name{
margin-top: -35px!important;
position: absolute!important;
margin-left: 55px!important;

}
.chat-noti-card{
border-radius: 5px!important;
background-color: #000000!important;
position: absolute!important;
width: 240px !important;
margin-left: 15%;
margin-top: 5px;


}
.chat-noti-text{
font-size: 14px;
color:#FFFFFF;
margin-top: 5px!important;
margin-left: 10px!important;


}
.chat-noti-img{
width: 90px!important;
height: 70px!important;
margin-left: 5px!important;
margin-top: 5px!important;
margin-bottom: 5px!important;
}
.home-noti-head{
  position: absolute!important;
  font-weight: 600;
  margin-left: 13%;
  margin-top: 5%;
}
.home-noti-head-two{
  position: absolute!important;
  font-weight: 600;
  margin-left: 13%;
  margin-top:-2%;
}

.cancel-button{
  background-image: linear-gradient(to bottom left, #c53232, #ca2525, #cf2f2f)!important;

}





.home-chats {
  transition: 0.3s ease-in-out;
  /* margin-right: -100%; */
}

.open {
  right:0;
  transition:  0.3s ease-in-out;
}

.toggles-icon {
  position: fixed;
  top: 10px;
  right: 20px;
  cursor: pointer;
  z-index: 1100;
  transition:  0.3s ease-in-out;
  margin-bottom: 100px!important;
}

.icon {
  font-size: 24px;
  color: #fff;
  transition:  0.3s ease-in-out;
}


