.live-chats{
    background-color: #161616;
    width:320px;
    border-radius: 0 50px 0 0;
    position: fixed;
    left: 0;
    z-index: 1093;
   height: 180vh;
  margin-top: -110px;
  zoom: 0.75;
  transition:  0.3s ease-in-out;

}
.stream-pics{
    margin-left:25%;
    border-radius: 50%!important;
    border: 4px solid red;
}
.live-main-chats{
position: fixed;
    margin-top: 40px;
     top: 0; 
    bottom: 0;
    width:320px;
    overflow-y: auto;
    

}
.live-main-chats:hover {
  overflow-y: auto;
 
 
  
}
.live-main-chats::-webkit-scrollbar {
  width: 8px;
  display: none!important;
  
}

.live-main-chats::-webkit-scrollbar-track {
  background-color: #F5F5F5;
}

.live-main-chats::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}


.live-main-chats::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  
}


.live-main-chats:hover::-webkit-scrollbar-thumb {
  background-color: #555;
}

