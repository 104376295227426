.user-sidebar{
 background-image: linear-gradient(to bottom left, #161616, #161616)!important;
 border-radius: 0 50px  0 10px!important;
   
    height:200vh!important;
    box-shadow:none!important;
     zoom: 0.80!important;
    
     
    
     
    
}
.sidebar{
width:300px!important;
/* overflow-y: auto; */

}
.dropDown-sidebar:hover{
     background-image: linear-gradient(to bottom left, #161616, #161616, #161616)!important;
   
 padding: 0.1rem 0.1rem!important;
  font-size: 0.25rem!important;
  margin-left: 400%!important;
  margin-top: -150%!important;
 
  
}
.dropDown-sidebar.active{
     background-image: linear-gradient(to bottom left, #6a097d, #6a097d, #6a097d)!important;
   
     padding: 0.1rem 0.1rem!important;
  font-size: 0.25rem!important;
  margin-left: 400%!important;
  margin-top: -150%!important;
 
  
}
.dropDown-sidebar{
     background-image: linear-gradient(to bottom left, #161616, #161616, #161616)!important;
     padding: 0.1rem 0.1rem!important;
  font-size: 0.25rem!important;
  margin-left: 400%!important;
  margin-top: -150%!important;
   
     
}
.drop-item{
    color: #d0d0d0 !important;
    font-size: 15px !important;
    margin-top: 10px!important;

}
.drop-icons{
    font-size: 15px!important;
}

.side-rap{
    overflow: hidden!important;
    width:285px!important
}
.ps__rail-x, .ps__rail-y {
  overflow: visible !important;
}

.user-chat-sidebar{
 background-image: linear-gradient(to bottom left, #ed2323, #d81515)!important;
 border-radius: 0 50px  0 10px!important;
   
    height:140vh!important;
    box-shadow:none!important;
     zoom: 0.75!important;
     
    
     
    
}


.sidebar-logo{
    width: 100px;
  height:100px;
  margin-top: -10px;
    margin-left: 100px;
    

}
.sidebar-links{
    margin-top: 0!important;
   
    
}
.links-name{
font-weight: 600;
font-size: 15px;
color:white!important;
margin-top: 6px!important;

}

.links-active{
    background-color:#6A097D;
   
   
  
   
}
.footer-main{
    margin-top: 5px!important;
    color:#CFCFCF !important;
    font-weight: 600;
    font-size: 15px;

}
.footer-text{
    font-size: 15px;
    color:#CFCFCF !important;
    font-weight: 600;
    margin-top: -12px!important;
}
.footer-textt{
    font-size: 13px;
    color:#CFCFCF !important;
    font-weight: 600;
    margin-top: -10px!important;
}
.footer-policy{
    font-size: 13px;
    color:#229ED9 !important;
    font-weight: 600;
     margin-top: -10px!important;
     cursor: pointer;
}
.footer-icons{
    
    color:white!important;
    font-size:30px!important
}


@media screen and (max-width: 991px){
.nav-open .sidebar {
    transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
    left:0!important
}
}
@media screen and (max-width: 991px){
 .sidebar {
    transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
    left:-300px!important
}
}
.accordian-text{
    margin-top: 10px!important;
    font-weight: 600;
    font-size: 14px!important;
}
.accordian-icon{
    font-size: 40px!important;
}





