.navbar-search{
    width: 350px!important;


}
.navbar-name{
    font-size: 15px;
    font-weight: 600;

}
.auth-anim-btn{
    background-image:linear-gradient(to bottom left, #4c0741, #430b58, #250625)!important ;
    font-size: 12px!important;
    border-radius: 10px!important;
    background-color:#4c0741!important ;
}

@media screen and (max-width: 950px){
 .auth-eroxr-text{
  display: none!important;
}
} 
.auth-eroxr-text{
    margin-left: 120px!important;
}
