.chat-upper-profile{
  width: 70px!important;
  height: 70px!important;
  object-fit: cover!important;
 
 
}
.dash-top-icons{
    width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #292929;
}
.profile-pic{
    width:100px;
    height:100px;
   
}
.chat-add-button{
     background-image: linear-gradient(to bottom left, black, black, black)!important;
    border-radius: 5px!important;

}
.chat-profile-button{
    
  
  
    background-image: linear-gradient(to bottom left, blueviolet, blueviolet, blueviolet)!important;
    border-radius: 5px!important;
}

.dashboard{
    background-color: #161616;
    transition:  0.3s ease-in-out;
    border-radius: 80px 0  0 10px;
    position: fixed;
    z-index: 1093;
    zoom: 0.90;
  
  right: 0;
  width:400px;
  
  
  height: 180vh;
  overflow: auto;
  top: 0;
  bottom: 0;
 
  
  
  
    
  
}
.dashboard-two{
    background-color:white;
    
    border-radius: 80px 0  0 10px;
    position: fixed;
    z-index: 1093;
    zoom: 0.90;
  
  right: 0;
  width:18%;
  
  
  height: 180vh;
  overflow: auto;
  top: 0;
  bottom: 0;
 
  
  
  
    
  
}
.dashboard-cards{
    position: fixed;
    
    margin-top: 7%;
    top: 0;
  bottom: 0;
}

  


.dashboard-cards:hover {
  overflow: auto;
  top: 0;
  bottom: 0;
  
  
  
}


.dashboard-cards::-webkit-scrollbar {
  width: 8px;
  display: none!important;
  
}

.dashboard-cards::-webkit-scrollbar-track {
  background-color: #F5F5F5;
  
}

.dashboard-cards::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}


.dashboard-cards::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  
}


.dashboard-cards:hover::-webkit-scrollbar-thumb {
  background-color: #555;
}
.dash-cards{
  background-color: #1c1a1a!important;
}
.dash-icons{
  font-size: 60px!important;
  color:#F5F5F5
}
