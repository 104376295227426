.modal-content {
  background-color: #161616!important;
  border-radius: 10px !important;
  box-shadow: 0px 0px 10px 0px black !important;
  margin-top: -80px;
  z-index: 2000 !important;
  opacity: 1;

}



.modal-contentOne {
  background-color: #1E1E1E !important;
  border-radius: 40px;

}

.modal-card {
  background-color: #161616 !important;
  border: 1px solid white !important;
  
  border-radius: 7px !important;
  
}

.modal-ok-button {
  background-image: linear-gradient(to bottom left, #6A097D, #6A097D, #6A097D) !important;
  border-radius: 5px !important;
  padding-left: 200px !important;
  padding-right: 200px !important;
}

.modal-capture-button {
  background-image: linear-gradient(to bottom left, #6A097D, #6A097D, #6A097D) !important;
  border-radius: 5px !important;
  padding-left: 180px !important;
  padding-right: 180px !important;
}

.modal-cancel-button {
  background-image: linear-gradient(to bottom left, #161616, #161616, #161616) !important;
  border-radius: 5px !important;
  padding-left: 180px !important;
  padding-right: 180px !important;
  border: 1px solid white !important;
}

.modal-post-pay-button {
  background-image: linear-gradient(to bottom left, #161616, #161616, #161616) !important;
  border-radius: 5px !important;
  /* padding-left:220px!important;
 padding-right: 220px!important; */
  border: 1px solid white !important;
}

.pay-cancel-btn {
  background-image: linear-gradient(to bottom left, #161616, #161616, #161616) !important;
  border-radius: 5px !important;
  padding-left: 130px !important;
  padding-right: 130px !important;
  border: 1px solid white !important;
}

.post-cancel-btn {
  background-image: linear-gradient(to bottom left, #161616, #161616, #161616) !important;
  border-radius: 5px !important;
  padding-left: 180px !important;
  padding-right: 180px !important;
  border: 1px solid white !important;
}

.pay-submit-btn {
  background-image: linear-gradient(to bottom left, #6A097D, #6A097D, #6A097D) !important;
  border-radius: 5px !important;
  padding-left: 180px !important;
  padding-right: 180px !important;

}

.picture-btn {
  z-index: 1500 !important
}

.modal {
  z-index: 1500 !important;
}

.call-modal {
  margin-top: 100px !important;
}

.accept-btn {
  background-color: greenyellow !important;
  background-image: linear-gradient(to bottom left, rgb(132, 182, 56), rgb(132, 182, 56), rgb(132, 182, 56)) !important;
}

.decline-btn {
  background-image: linear-gradient(to bottom left, rgb(255, 0, 0), rgb(255, 0, 0), rgb(255, 0, 0)) !important;
}

.tap-img-btn {
  display: flex;
  justify-content: center;
}

.tap-button {
  background-color: #fff;
  border: none;
  border-radius: 20px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin: 10px;
  padding: 10px 20px;
}

.tap-text {
  color: #25D366;
  font-size: 16px;
  font-weight: bold;
}

.modal-recording-button {
  background-image: linear-gradient(to bottom left, #6A097D, #6A097D, #6A097D) !important;
  border-radius: 5px !important;
  color: white;
  padding-left: 130px !important;
  padding-right: 130px !important;


}

.tip-btn {
  position: absolute !important;
  margin-top: -58px !important;
  background-image: linear-gradient(to bottom left, #2c2f3e, #2c2f3e, #2c2f3e) !important;
  margin-left: 21% !important;
  padding: 0.6em !important;


}

input[type="number"] {
  color: white !important;

}

input {
  color: white !important;

}
