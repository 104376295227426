.dash-send-button{
    border-radius: 0px 20px !important;
    margin-bottom: -15px!important;
    margin-left: -15px!important;
    margin-right: -15px!important;
    background-image: linear-gradient(to bottom left, #000000, #000000, #000000)!important;
   

}
.dash-add-button{
    border-radius: 20px 0px !important;
    margin-bottom: -15px!important;
    margin-right: -15px!important;
    background-image: linear-gradient(to bottom left, #000000, #000000, #000000)!important;
    
    
    

}
@media only screen and (min-width: 1200px) {
 .bar-col{
margin-top: -15%!important ;
}
.bar-card{
margin-left:10% !important ;
}
.bar-card-body{
margin-top: 30%;
}


}
@media only screen and (max-width: 1200px) {

.vertical-progress-bar-whole {
 display: none !important;
 
}

}




.progress-wrapper {
  width: 100px;
  height: 100px;
  position: relative;
}

.circular-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
  border-radius: 50%;
  box-shadow: inset 0 0 0 6px #f5f5f5;
}

.circular-progress::-webkit-progress-value {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #007bff;
  box-shadow: inset 0 0 0 2px #f5f5f5;
}

.circular-progress::-moz-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #007bff;
  box-shadow: inset 0 0 0 2px #f5f5f5;
}
.vertical-progress-bar {
  height: 120px!important;
  width: 5px!important;
  position: relative!important;
  background-color: white!important;
  margin-left: 2%;
 
}

.vertical-progress-bar .progress {
  position: absolute!important;
  top: 0!important;
  bottom: 0!important;
  left: 0!important;
  right: 0!important;
  padding-top: 100px!important;
 
}

.vertical-progress-bar .progress-bar {
  background-color: #007bff!important;
}
.vertical-progress-bar-whole {
  height: 125vh!important;
  width: 0!important;
  position: relative!important;
  background-color: #000000!important;
  margin-left: 3%;
  margin-top: -17%!important;
 
}

.vertical-progress-bar-whole .progress {
  position: absolute!important;
  top: 0!important;
  bottom: 0!important;
  left: 0!important;
  right: 0!important;
  padding-top: 100px!important;
 
}

.vertical-progress-bar-whole .progress-bar {
  background-color: #007bff!important;
}
.curve-overlay {
  position: absolute!important;
  width: 10px!important;
  height: 10px!important;
  border-radius: 50%!important;
  background-color: white!important;
  top: 50%!important;
  left: 50%!important;
  transform: translate(-50%, -50%)!important;
  z-index: 1!important;
}
.dash-profile{
    border-radius: 50%;
    position:relative!important;
     object-fit: cover!important;
    z-index: 4!important;
    margin-top: -310px!important;
    margin-left:35%;
   

    background-color: #1e1e26;
    padding: 3px;
    width:50px;
    height: 50px;

}
.horizontal-progress-bar-whole {
  height: 0px!important;

width: 100pxpx !important;
  background-color: #000000!important;
  margin-left: -17%!important;

  
 
}
.dashboard-slider{
  width:20%!important
}
.tip-card{
  background-color:#161616!important;
  border-radius:20px!important;
  width:80%!important;
  height:200px!important;
  overflow:auto!important
}
.tip-card::-webkit-scrollbar {
 
  display: none!important;
  
}

