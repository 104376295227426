.backgroundImage{
    height: 130.5vh !important;
    zoom:0.90!important;
    background-repeat: no-repeat!important;
    background:cover!important;
    background-size: cover!important;
    background-position: center center!important;
}
@media screen and (max-width: 1190px) {
  .backgroundImage {
     height: 160vh !important;
  }
}
.body{
  /* zoom:0.90!important; */
}