.poll {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: black;
  color: white;
}

.poll-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
}

.poll-options {
  list-style-type: none;
  padding: 0;
}

.poll-option {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.poll-option-text {
  flex-grow: 1;
  margin-right: 10px;
}

.poll-option-btn {
  background-color: purple;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.poll-option-count {
  font-size: 14px;
  color: gray;
}
.progress-bars{
    height:25px!important;
    
    background-color: azure!important;
}
.poll-card{
  background-color: black!important;
  border-radius: 40px!important;
  width: 750px!important;
  animation:1s linear red!important;
  
  
}
.poll-option{
  background-image: linear-gradient(to bottom left, black, black, black)!important;
  border: 2px solid purple!important;
  font-weight: 700!important;
  border-radius: 20px 20px 20px 20px!important;
  padding-left: 300px!important;
  padding-right: 300px!important;
  margin-top: 20px!important;
  font-size: 18px!important;

}