

.profile-card {
    background-color: white;
    border-radius: 20px; /* Adjust the border radius to control the roundness of edges */
    width:424px!important;
    padding: 15px 15px 15px;;
    box-shadow: 10px 10px 10px rgba(255, 255, 255, 0.1);
    text-align: center;
    height:150px
}

.profile-image{
   width: 50px!important;
   height: 50px!important;
    border-radius: 50%;
    object-fit: cover;
}

.name {
    font-weight: bold;
    font-size: 15px;
    margin: 0;
    color: black;
    margin-top: -50px;
}




.banner {
  width: 100%;
  height: 100px; /* Adjust the height as needed */
  position: relative;
  animation: moveBanner 20s linear infinite; /* Adjust the duration (5s) as needed */
  margin-top: 15%;

  /* Optional: Add styling to make the banner more visually appealing */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  transform: rotate(-2.2deg);
  margin-bottom: 15%;
  

}

@keyframes moveBanner {
  0% {
    right: -100%; /* Start position, off the left side of the screen */
    /* transform: translateY(150%) translateX(0);  */
    /* No diagonal translation */
  }
  100% {
    right: 100%; /* End position, off the right side of the screen */
    /* transform: translateY(-100%) translateX(0); */
     /* Diagonal translation from bottom-left to top-right */
  }
}



body {
  overflow-x: hidden;
}