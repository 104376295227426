.video-accept-btn{
 background-image: linear-gradient(to bottom left, #6A097D, #6A097D, #6A097D)!important;
 border-radius: 5px!important;

}

.video-reject-btn{
 background-image: linear-gradient(to bottom left, #1e1e1e, #1e1e1e, #1e1e1e)!important;
 border-radius: 5px!important;

 border: 1px solid white!important;
}
.verify-video{
    border-radius: 10px 10px 10px 10px;
    
}