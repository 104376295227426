@media screen and (min-width: 1000px) {
    .footer-items{
     margin-left:20%!important;
      
     
    
    }
    

}
