.top-icons{
    font-size: 20px;
    margin-top: 20px;
    margin-left: 30px;
    color: grey;
}
.chat-inputs{
    width:350px!important;
       
    background-color: #222222!important;
    padding: 7px 0px 5px 10px!important;
    border-radius:0px 50px 50px 30px!important ;
    margin-left: -5px;
   
    border:1px Solid white!important;
    line-height: 1!important;
    
    
    
}
.chat-second-inputs{
    width:350px!important;
       
    background-color: #222222!important;
    padding: 7px 0px 5px 10px!important;
    border-radius:50px 0 50px 50px!important ;
    margin-right: 4px;
    border:1px Solid white!important;
     line-height: 1!important;
}
.chat-thired-inputs{
   
       
    background-color:#1e1e26!important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    border-radius: 0 0 0 7px!important;
    
    
    border:none!important;
   
}
.chat-attach{
    background-image: linear-gradient(to bottom left, #1e1e26, #1e1e26, #1e1e26)!important;
    border: none!important;
}

.message-div{

    
   
 position: fixed !important;
 height: 65vh !important;
 overflow-y: auto !important;
 width:55%!important


 
 
 
 
 
}
.message-div:hover {
  overflow-y: auto;

}
.message-div::-webkit-scrollbar {
  
  display: none!important;
  
  display: none!important;
}

.message-div::-webkit-scrollbar-track {
  background-color: #F5F5F5;
  display: none!important;
}

.message-div::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
  display: none!important;
}


.message-div::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  display: none!important;
  
}


.message-div:hover::-webkit-scrollbar-thumb {
  background-color: #555;
  display: none!important;
}
.chat-below-div{
  position: relative!important; 
  margin-top:75vh !important;
  

   
 
}
.camera-img{
width: 50px !important;
height: 50px !important;
border-radius: 50%!important;
}
.gallery-img{
width: 50px !important;
height: 50px !important;
border-radius: 50%!important;

}
.attachment-div{
  position: absolute!important;
  margin-top: -110px!important;
  cursor: pointer!important;
}
.chat-second-img{
  margin-left: 190px!important;
  margin-top:3%!important ;
 
}
.msg-blur-text{
  color:white!important;
  position:absolute!important;
  margin-top: -20%!important;
  margin-left: 50%!important;
  cursor: pointer;
}
.drop-chat{
  background-image: none!important;
   padding: 0rem 0rem!important;
  font-size: 0rem!important;
  box-shadow: none!important;
 
   
}

.drop-chat:hover{
  background-image: linear-gradient(to bottom left, #1e1e2e, #1e1e2e, #1e1e2e)!important;
  padding: 0rem 0rem!important;
  font-size: 0rem!important;
  box-shadow: none!important;
  
}
.more-icon:hover{
background-image: linear-gradient(to bottom left, #1e1e2e, #1e1e2e, #1e1e2e)!important;
   font-size: 20px;
    margin-top: 20px;
    margin-left: 30px;
  
}
.more-icon{
background-image: linear-gradient(to bottom left, #1e1e2e, #1e1e2e, #1e1e2e)!important;
   font-size: 20px;
    margin-top: 20px;
    margin-left: 30px;
    cursor:pointer
 
}

@keyframes loading {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

.loading {
  animation: loading 1.5s infinite;
}
@media screen and (max-width: 1200px) {
  .message-div {
  position: fixed !important;
 height: 65vh !important;
 overflow-y: auto !important;
 width:100%!important;
 
  }
  .whole-div {
  margin-top:-100px!important
 
  }
}


